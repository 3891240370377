import { OrderByDirection } from '@dep/common/interfaces';
import { NGXLogger } from 'ngx-logger';

const imgSortArrowUp = '<img src="assets/icons/sort_arrow_up.png" srcset="assets/icons/sort_arrow_up@2x.png 2x">';
const imgSortArrowDown = '<img src="assets/icons/sort_arrow_down.png" srcset="assets/icons/sort_arrow_down@2x.png 2x">';
const imgSortArrowBoth = '<img src="assets/icons/sort_arrow_both.png" srcset="assets/icons/sort_arrow_both@2x.png 2x">';

enum OrderBy {
  DUMMY = 'DUMMY', // TODO: Remove if backend is complete.
  STATUS = 'status',
  DATE = 'date',
  LEGAL_NAME = 'legalNameKw',
  DEALERSHIP_LEGAL_NAME = 'dealership.legalNameKw',
  CITY = 'cityKw',
  ZIP = 'zipKw',
  COUNTRY = 'countryKw',
  GSSN = 'gssnKw',
  PRODUCT_NAME = 'productTypeKw',
  RELEVANCE = 'relevance',
}

type Constructor<T> = new (...args: any[]) => T;
function FilterSortMixin<T extends Constructor<any>>(Base: T): T {
  return class extends Base {
    public searchTermInput = '';
    public searchTerm = '';
    public orderBy = '';
    public orderByDirection: OrderByDirection = OrderByDirection.ASC;

    public search(): void {
      if (!this.searchTermInput.trim() && !this.searchTerm) {
        return;
      }

      this.searchTerm = this.searchTermInput.trim() ? this.searchTermInput : '';
      this.logger.debug(`Searching "${this.searchTerm}"`);

      this.orderBy = OrderBy.RELEVANCE;

      this.updateNavParams(1);
    }

    public onSearchTermInputSearch() {
      if (this.searchTermInput === '') {
        this.searchTerm = this.searchTermInput;
        this.updateNavParams(1);
      }
    }

    public getOrderByIcon(col: OrderBy | string): string {
      let colOrderBy: OrderBy | undefined;
      if (typeof col === 'string') {
        colOrderBy = Object.keys(OrderBy).find(
          // @ts-expect-error
          (k) => OrderBy[k] === col,
        ) as OrderBy | undefined;
      } else {
        colOrderBy = col;
      }

      if (this.orderBy === colOrderBy?.valueOf()) {
        if (this.orderByDirection === OrderByDirection.DESC) {
          return imgSortArrowUp;
        }
        if (this.orderByDirection === OrderByDirection.ASC) {
          return imgSortArrowDown;
        }
      }
      return imgSortArrowBoth;
    }

    public order(col: OrderBy | string): void {
      let colOrderBy: OrderBy | undefined;
      if (typeof col === 'string') {
        colOrderBy = Object.keys(OrderBy).find(
          // @ts-ignore
          (k) => OrderBy[k] === col,
        ) as OrderBy | undefined;
      } else {
        colOrderBy = col;
      }

      if (this.orderBy === col) {
        this.orderByDirection = (this.orderByDirection === OrderByDirection.ASC) ? OrderByDirection.DESC : OrderByDirection.ASC;
      } else {
        this.orderBy = colOrderBy || '';
        this.orderByDirection = (this.orderBy === OrderBy.DATE.valueOf()) ? OrderByDirection.DESC : OrderByDirection.ASC;
      }

      this.updateNavParams(1);
    }
  };
}

interface FilterSortMixinInterface {
  logger: NGXLogger;
  updateNavParams: (page?: number) => void;
}

export { FilterSortMixin, FilterSortMixinInterface, OrderBy };
